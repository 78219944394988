import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className="h1">Hello!</h1>
    <p>
      I am a software developer in Philadelphia, PA. My focus and passion lies in architecting,
      developing, testing, and deploying accessible, performant, and enjoyable web applications.
    </p>
    <dl>
      <dt>Pronouns:</dt>
      <dd>he/him/his</dd>
      <dt>Github:</dt>
      <dd><a href="https://github.com/brendanmckeown">brendanmckeown</a></dd>
      <dt>LinkedIn:</dt>
      <dd><a href="https://www.linkedin.com/in/brendmckeown/">Brendan McKeown</a></dd>
    </dl>
  </Layout>
);

export default IndexPage;
